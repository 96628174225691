<script setup lang="js">
import { computed, defineProps, onMounted } from "vue";

const props = defineProps({
    userCard: {
        type: Object,
        required: true,
    },
});
const config = computed(() => props.userCard.card.config.filter((value) => value.type === 'stamp')[0]);
const configVisual = computed(() => props.userCard.card.config_visual);

const stepValues = computed(() => config.value.steps.map(item => item.key));

const steps = computed(() => listSteps())
const listSteps = () => {
  let maxValue = 0;
  let maxStamp = 10;

  config.value.steps.map((item) => {
    maxValue = Math.max(maxValue, item.key);
  })
  
  let minStamp = props.userCard.stamps;
  if (minStamp > maxValue - 10){
    minStamp = maxValue - 9;
  }

  if (minStamp <= 0) {
    minStamp = 1;
  }

  if(maxValue < maxStamp) {
    maxStamp = maxValue
  }
  
  
  let result = [];
  for(let i = 0; i < maxStamp; i++){
    result.push(i + minStamp)
  }
  
  return result;
}

onMounted(async () => {

});

</script>

<template>
  <div class="card">
    <div class="card_header">
      <img v-if="config.logo" height="35" :src="config.logo" alt="logo" />
      <div class="card_header_text">
        <div class="card_header_text_item">
          <span v-if="userCard.card_view.card_name" class="mr-9-prim medium ttu">{{
            userCard.card_view.card_name
          }}</span>
        </div>
      </div>
    </div>
    <div class="card_data">
      <ul v-if="steps && steps.length > 0" class="card_data_presents">
        <li
        v-for="(indexNumber, index) of steps"
          :key="index"
          class="card_data_presents_item"
        >
          <svg 
            v-if="userCard.stamps >= indexNumber" 
            width="40" 
            height="40" 
            viewBox="0 0 40 40" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="7" fill="black" fill-opacity="0.1"/>
            <path :fill="configVisual.text_color" d="M24.8888 15.635C24.8888 15.8628 24.8099 16.0643 24.6522 16.2396L17.9221 22.9697C17.7469 23.1274 17.5453 23.2063 17.3175 23.2063C17.0896 23.2063 16.8881 23.1274 16.7128 22.9697L13.3478 19.6046C13.1901 19.4294 13.1112 19.2278 13.1112 19C13.1112 18.7546 13.1988 18.5531 13.3741 18.3953C13.5318 18.2376 13.7246 18.1587 13.9525 18.1587C14.1803 18.1587 14.3819 18.2376 14.5571 18.3953L17.3175 21.182L23.4429 15.0303C23.6181 14.8726 23.8197 14.7937 24.0475 14.7937C24.2754 14.7937 24.4682 14.8726 24.6259 15.0303C24.8012 15.188 24.8888 15.3896 24.8888 15.635Z"/>
          </svg>

          <svg
            v-else-if="stepValues.includes(indexNumber)"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="37"
            viewBox="0 0 31 39"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.4937 18.3502L13.5761 20.2256L18.4468 21.2381L29.8833 15.654L29.2313 31.7925L18.6884 38.7879L1.56044 34.4656L0.0263672 18.1699L6.12815 14.6646C2.85673 11.4162 2.36966 7.05556 2.47705 4.63173L9.5568 0.144562C14.2817 0.819554 16.0574 5.72475 15.8657 9.32599C17.2617 6.53398 21.1084 1.39483 26.282 2.9826L30.6542 8.48992C30.1748 13.9397 23.2254 19.4164 15.4937 18.3502ZM4.04564 32.4713L17.3998 35.5395L17.3115 23.8307L13.7218 23.1173L14.3201 31.5547L9.25766 31.478L9.29601 28.6707H5.53753L6.18951 21.6139L2.76852 20.9351L4.04564 32.4713ZM4.29109 18.3042L8.24517 19.1249L11.0372 17.6637C9.98463 17.2964 8.97922 16.8059 8.0419 16.2025L4.29109 18.3042ZM5.71778 8.01819C5.44932 10.1045 6.71876 12.862 11.2136 14.864C11.0947 11.3548 9.05056 8.51293 5.71778 8.01819ZM26.1823 10.8984C22.9301 10.4382 19.8658 12.862 18.6385 15.8842C22.838 15.6886 25.7489 13.2494 26.1823 10.8984Z"
              :fill="configVisual.text_color"
            />
          </svg>
          
          <div class="card_data_presents_item_step" v-else>
            <span
              :style="`color: ${configVisual.text_color};`"
              class="mr-12-prim medium"
              >{{ indexNumber }}</span>
          </div>
        </li>
      </ul>
      <div class="card_data_mid">
        <div v-if="userCard.card_view.left_title && userCard.card_view.left_value" class="card_data_mid_item">
          <span class="mr-9-prim ttu medium">{{userCard.card_view.left_title}}</span>
          <span class="mr-14-prim medium ttl"
            >{{ userCard.card_view.left_value }} 
          </span>
        </div>
        <div v-if="userCard.card_view.right_title && userCard.card_view.right_value"  class="card_data_mid_item_end">
          <span class="mr-9-prim ttu medium">{{userCard.card_view.right_title}}</span>
          <span class="mr-14-prim medium">
            {{ userCard.card_view.right_value }}
          </span>
        </div>
      </div>
    </div>
    <img 
      v-if="userCard.lending_qr" 
      :src="userCard.lending_qr" 
      height="120"
      alt="qr"
    >
  </div>
</template>

<style scoped lang="scss">
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &_text {
      display: flex;
      gap: 14px;
      width: 100%;
      justify-content: flex-end;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &_data {
    width: 100%;

    &_presents {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &_item {
        height: 40px;
        &_step {
          border-radius: 7.84px;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &_mid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      &_item {
        display: flex;
        flex-direction: column;
        width: 50%;
        &_end {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    &_empty {
      width: 100%;
      height: 88px;
      border-radius: 6px;
      background: rgba(78, 127, 255, 0.25);
      margin-bottom: 12px;
    }
  }
}
</style>
