<script setup lang="js">
import { computed, defineProps } from "vue";

const props = defineProps({
    userCard: {
        type: Object,
        required: true,
    },
});
const config = computed(() => props.userCard.card.config);
</script>

<template>
  <div class="card">
    <div class="card_header">
      <img v-if="config.logo" height="35" :src="config.logo" alt="logo" />
      <div class="card_header_text">
        <div v-if="userCard.card_view.second_left_title && userCard.card_view.second_left_value" class="card_header_text_item">
          <span class="mr-9-prim ttu medium">{{userCard.card_view.second_left_title}}</span>
          <span class="mr-14-prim medium">{{
            userCard.card_view.second_left_value
          }}</span>
        </div>
        <div v-if="userCard.card_view.second_right_title && userCard.card_view.second_right_value" class="card_header_text_item">
          <span class="mr-9-prim ttu medium">{{userCard.card_view.second_right_title}}</span>
          <span class="mr-14-prim medium">
            {{userCard.card_view.second_right_value}}
          </span>
        </div>
      </div>
    </div>
    <div class="card_data">
      <div class="card_data_empty"></div>
      <div class="card_data_mid">
        <div v-if="userCard.card_view.left_title && userCard.card_view.left_value" class="card_data_mid_item">
          <span class="mr-9-prim ttu medium">{{userCard.card_view.left_title}}</span>
          <span class="mr-14-prim medium">{{userCard.card_view.left_value}}</span>
        </div>
        <div v-if="userCard.card_view.right_title && userCard.card_view.right_value" class="card_data_mid_item_end">
            <span class="mr-9-prim ttu medium">{{userCard.card_view.right_title}}</span>
            <span class="mr-14-prim medium">{{userCard.card_view.right_value}}</span>
        </div>
      </div>
    </div>
    <img 
      v-if="userCard.lending_qr" 
      :src="userCard.lending_qr" 
      height="120"
      alt="qr"
    >
  </div>
</template>

<style scoped lang="scss">
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &_text {
      display: flex;
      gap: 14px;
      width: 100%;
      justify-content: flex-end;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &_data {
    width: 100%;

    &_presents {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &_item {
        &_step {
          font-family: Manrope;
          font-size: 11.76px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.68px;
          border-radius: 7.84px;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &_mid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      &_item {
        display: flex;
        flex-direction: column;
        width: 50%;
        &_end {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    &_empty {
      width: 100%;
      height: 88px;
      border-radius: 6px;
      background: rgba(78, 127, 255, 0.25);
      margin-bottom: 12px;
    }
  }
}
</style>
