<script setup lang="js">
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import StampCard from "./components/stamp-card.vue";
import CashbackCard from "./components/cashback-card.vue";
import DiscountCard from "./components/discount-card.vue";
import SubscriptionCard from "./components/subscription-card.vue";
import CouponCard from "./components/coupon-card.vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCzuN63jDY-I6bu1fhofsGsPsLDytmwDB0",
  authDomain: "forward-ace-250308.firebaseapp.com",
  projectId: "forward-ace-250308",
  storageBucket: "forward-ace-250308.appspot.com",
  messagingSenderId: "578545425942",
  appId: "1:578545425942:web:2d6e318bfcf879c9065f37",
  measurementId: "G-2RE3PCNELD"
};

const app = initializeApp(firebaseConfig);
console.log(app);
const userCard = ref(null);
const tokenPush = ref(null);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});

getToken(messaging, { vapidKey: 'BEo4UqYO30oFuBzG0TuLRgm17iHTjA3WzzNHWFTgGdp7j1WROTsJcWaZQO86FLNzJijmMmi5LCSVHEsmQmO-2q0' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log("Token is:",currentToken);
    tokenPush.value = currentToken;
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

const BASE_URL = 'https://api.wahelp.cards/api/v1';
// const BASE_URL = 'http://localhost/api/v1';

const setTheme = (theme) => {
  const html = document.documentElement;

  if (theme === "dark") {
    html.dataset.theme = "dark";
  } else if (theme === "light") {
    delete html.dataset.theme;
  }
};

(() => {
  let theme;

  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    theme = "dark";
  } else {
    theme = "light";
  }
  setTheme(theme);
})();

const getClient = async (clientId) => {
  try {
    const response = await axios.get(`${BASE_URL}/clients/${clientId}/cards/active?has_qr=true`);
    
    if(response.data.card.card_config){
      response.data.card.card_config.forEach(element => {
        if(element.type === 'cashback'){
          response.data.card.config = element;
        }
      });

      if(!response.data.card.config){
        if(response.data.card.card_config.length > 0){
          response.data.card.config = response.data.card.card_config;
        }
      }
      
    }
    userCard.value = response.data;
    localStorage.setItem("clientId", JSON.stringify(clientId));
  } catch (e) {
    console.log(e);
  }
}

const getClientCard = async (clientCardId) => {
  try {
    const response = await axios.get(`${BASE_URL}/client-card/${clientCardId}?has_qr=true`);
    
    if(response.data.card.card_config){
      response.data.card.card_config.forEach(element => {
        if(element.type === 'cashback'){
          response.data.card.config = element;
        }
      });

      if(!response.data.card.config){
        if(response.data.card.card_config.length > 0){
          response.data.card.config = response.data.card.card_config;
        }
      }
      
    }
    userCard.value = response.data;
    localStorage.setItem("clientCardId", JSON.stringify(clientCardId));
  } catch (e) {
    console.log(e);
  }
}

const setClientCardTokenPush = async () => {
  try {
    console.log(userCard.value);
    let request = {
      client_card_id: userCard.value.id,
      token: tokenPush.value,
      client_id: userCard.value.client.id,
      project_id: userCard.value.client.project_id
    };
    const response = await axios.post(`${BASE_URL}/pwa/wallet/register`, request);
    console.log(response);
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  const params = new URLSearchParams(location.search);

  let clientId = params.get("clientId");
  let clientCardId = params.get("clientCardId");
  if (clientCardId !== null) {
    await getClientCard(clientCardId);
    return;
  } 
    
  if (clientId !== null) {
    await getClient(clientId);
    return;
  }
  
  if(!clientCardId){
    clientCardId = JSON.parse(localStorage.getItem("clientCardId"));
  }

  if (!clientId) {
    clientId = JSON.parse(localStorage.getItem("clientId"));
  }

  if (clientCardId !== null) {
    await getClientCard(clientCardId);
    return;
  } 
    
  if (!clientId) {
    await getClient(clientId);
    return;
  }
});

watch(userCard, () => {
  if (userCard.value === undefined) {
    return;
  }
  setClientCardTokenPush();
})

</script>

<template>
  <div
    v-if="userCard"
    class="app"
  >
    <div 
      v-if="userCard"
      class="card_wrap"
      :style="`
        color: ${userCard.card.config.text_color};
        background-color: ${userCard.card.config.background_color};
      `"
    >
      <stamp-card v-if="userCard.card.type === 'stamp'" :user-card="userCard" />
      <cashback-card v-else-if="userCard.card.type === 'cashback'" :user-card="userCard" />
      <stamp-card v-else-if="userCard.card.type === 'multicard'" :user-card="userCard" />
      <discount-card v-else-if="userCard.card.type === 'discount'" :user-card="userCard" />
      <subscription-card v-else-if="userCard.card.type === 'subscription'" :user-card="userCard" />
      <coupon-card v-else-if="userCard.card.type === 'coupon'" :user-card="userCard" />
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/styles";
</style>
